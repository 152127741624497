exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-natureflip-jsx": () => import("./../../../src/pages/contact-natureflip.jsx" /* webpackChunkName: "component---src-pages-contact-natureflip-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/contact-success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-article-category-template-js": () => import("./../../../src/templates/articleCategoryTemplate.js" /* webpackChunkName: "component---src-templates-article-category-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-articles-list-template-js": () => import("./../../../src/templates/articlesListTemplate.js" /* webpackChunkName: "component---src-templates-articles-list-template-js" */),
  "component---src-templates-attraction-template-js": () => import("./../../../src/templates/attractionTemplate.js" /* webpackChunkName: "component---src-templates-attraction-template-js" */)
}

